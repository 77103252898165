<template>
  <div class="flex items-center justify-center cell-renderer-deposit-rule-checkbox-container">
    <vs-checkbox v-model="params.data.isActive" disabled />
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
/* Make sure the checkbox centers vertically in the row*/
.cell-renderer-deposit-rule-checkbox-container {
  height: inherit;
}
</style>