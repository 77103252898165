<template>
  <div>
    <feather-icon title="View Details" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onView" />
    <feather-icon title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onEdit" />
    <feather-icon title="Delete" icon="TrashIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="showDeletePopup=true" />
    
    <vs-prompt
      title="Delete Deposit Option"
      accept-text="Delete"
      @accept="deleteDepositRule"
      :active.sync="showDeletePopup">

        <div class="vx-row mt-2 mb-2">
          <div class="vx-col w-full">
            <div class="">Are you sure you want to delete the deposit rule - {{params.data.name}}?</div>
          </div>
        </div>
    </vs-prompt>

  </div>
</template>

<script>
import _ from 'lodash'
import axios from '@/axios'

export default {
  data () {
    return {
      showDeletePopup: false
    }
  },
  computed: {
  },
  methods: {
    onEdit () {
      this.$router.push({ name: 'application-depositrules-edit', 
        params: { 
          depositRuleId: this.params.data.id,
        }
      })
    },
    onView () {
      this.$router.push({ name: 'application-depositrules-view', 
        params: { 
          depositRuleId: this.params.data.id,
        }
      })
    },
    deleteDepositRule () {
      this.params.context.componentParent.deleteDepositRule(this.params.data.id)
    }
  }
}
</script>
