<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <!-- <div class="vx-row"> -->
          <!-- <div class="vx-col w-full"> -->
          <div class="flex flex-wrap justify-start">
            <vs-button @click="addDepositRule" class="mr-4 sm:mb-0 mb-2">Add</vs-button>
            <vs-button @click="manageDepositOptions" type="border" class="mr-4 sm:mb-0 mb-2">Manage Deposit Options</vs-button>
          </div>
        <!-- </div> -->
        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="depositRulesList"
          :gridOptions="gridOptions"
          :autoGroupColumnDef="autoGroupColumnDef"
          rowSelection="single"
          :pagination="true"
          :context="context"
          :suppressPaginationPanel="false"
        >
        </ag-grid-vue>

      </vx-card>
    </div>
  </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import { format } from 'date-fns'
import CellRendererDepositRulesActiveCheckbox from './cell-renderers/CellRendererDepositRulesActiveCheckbox.vue'
import CellRendererDepositRulesActions from './cell-renderers/CellRendererDepositRulesActions.vue'

export default {
  components: {
    AgGridVue,
    CellRendererDepositRulesActiveCheckbox,
    CellRendererDepositRulesActions
  },
  props: {
    applicationId: { required: true }
  },
  data () {
    return {
      depositRulesList: [],
      categoryList: [],
      autoGroupColumnDef: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererDepositRulesActiveCheckbox,
        CellRendererDepositRulesActions
      },
      context: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    this.$vs.loading()
      this.$http.get(`/api/depositrules/apps/${this.applicationId}`)
      .then(response => {
          this.depositRulesList = response.data
      })
      .catch(error => console.error(error))
      .finally(() => this.$vs.loading.close())  
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Actions', width: 150, suppressSizeToFit: true, sortable: false, cellRendererFramework: Vue.extend(CellRendererDepositRulesActions) },
      { field: 'id', width: 120, suppressSizeToFit: true },
      { headerName: 'Name', field: 'name', width: 300,  suppressSizeToFit: false },
      { headerName: 'Priority', field: 'priority', width: 180,  suppressSizeToFit: true },
      { headerName: 'IsActive', field: 'isActive', width: 180,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererDepositRulesActiveCheckbox) },
      {
        headerName: 'Created',
        field: 'created',
        sortable: true,
        suppressSizeToFit: true,
        width: 250,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

    this.autoGroupColumnDef = { minWidth: 200 };
    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    addDepositRule () {
      this.$router.push({ name: 'application-depositrules-add', 
        params: { 
          applicationId: this.applicationId,
        }
      })
    },
    deleteDepositRule (depositRuleId) {
      this.$vs.loading()
      this.$http.delete(`/api/depositrules/apps/${this.applicationId}/rules/${depositRuleId}`)
      .then(response => {
          this.depositRulesList = this.$_.filter(this.depositRulesList, x => x.id !== depositRuleId)
          // Reassign deopositOptionsList to trigger change detection
          this.depositRulesList = JSON.parse(JSON.stringify(this.depositRulesList))
          this.$_notifySuccess('Deposit option successfully deleted');
      })
      .catch(error => console.error(error))
      .finally(() => this.$vs.loading.close())  
    },
    manageDepositOptions() {
      this.$router.push({
        name: "application-depositoptions",
        params: { applicationId: this.$route.params.applicationId },
      });
    }
  }
}
</script>

<style>

</style>